@font-face {
    font-family: 'Founders Grotesk';
    src: url('./assets/fonts/FoundersGrotesk-BoldItalic.woff2') format('woff2'),
        url('./assets/fonts/FoundersGrotesk-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

/* 
@font-face {
    font-family: 'Founders Grotesk';
    src: url('./assets/fonts/FoundersGrotesk-Bold.woff2') format('woff2'),
        url('./assets/fonts/FoundersGrotesk-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
} */

@font-face {
    font-family: 'Founders Grotesk';
    src: url('./assets/fonts/FoundersGrotesk-Regular.woff2') format('woff2'),
        url('./assets/fonts/FoundersGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: url('./assets/fonts/FoundersGrotesk-Light.woff2') format('woff2'),
        url('./assets/fonts/FoundersGrotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: url('./assets/fonts/FoundersGrotesk-LightItalic.woff2') format('woff2'),
        url('./assets/fonts/FoundersGrotesk-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}



@font-face {
    font-family: 'Cormorant Infant';
    src: url('./assets/fonts/CormorantInfant-Bold.woff2') format('woff2'),
        url('./assets/fonts/CormorantInfant-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Infant';
    src: url('./assets/fonts/CormorantInfant-Light.woff2') format('woff2'),
        url('./assets/fonts/CormorantInfant-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Infant';
    src: url('./assets/fonts/CormorantInfant-Medium.woff2') format('woff2'),
        url('./assets/fonts/CormorantInfant-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Infant';
    src: url('./assets/fonts/CormorantInfant-Regular.woff2') format('woff2'),
        url('./assets/fonts/CormorantInfant-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Infant';
    src: url('./assets/fonts/CormorantInfant-SemiBold.woff2') format('woff2'),
        url('./assets/fonts/CormorantInfant-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}




* {
    font-family: 'Founders Grotesk';
}

body {
    font-size: 16px;
    margin: 0;
    color: #050504 !important;
    font-family: 'Founders Grotesk';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    font-size: 16px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

img {
    max-width: 100%;
}

.pressHover {
    position: relative;
    cursor: pointer;
}

img.second {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
}

.pressHover:hover .second {
    display: block;
    opacity: 1;
    visibility: visible;
}

.NavLogo {
    padding: 20px 20px !important;
    max-width: 786px;
    margin-left: auto;
    margin-right: auto !important;
    display: block;
}



.FotLogo {
    padding: 13px 20px !important;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto !important;
    display: block;
}

.navbar .nav-link {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    color: #050504;
    padding: 3px 20px !important;
    letter-spacing: 0.6px;
}

.navbar .nav-link:hover,
.navbar .nav-link:active,
.navbar .nav-link:focus {
    color: #97896f;
}

.CtaBtn {
    position: relative;
    max-width: 165px;
    min-width: 165px;
    margin: auto;
    display: block;
}

.CtaBtn .BtnHover {
    opacity: 0;
    visibility: hidden;
    height: 0;
    width: 0;
}

.CtaBtn:hover .BtnHover {
    opacity: 1;
    visibility: visible;
    height: auto;
    width: 100%;
}

.CtaBtn:hover .Btn {
    opacity: 0;
    visibility: hidden;
    height: 0;
    width: 0;
}

.feat-img {
    position: relative;
}

.feat-img img {
    width: 100%;
}



.ArrowXl .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -55px;
    width: 95px;
    height: 95px;
    font-size: 0;
    border: 0.5px solid #fff;
    background-color: transparent;
    border-radius: 50%;
    background-size: 95px;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    transition: all ease 0.2s;
}

.ArrowXl .slick-arrow:hover {
    border-color: #fff;
}


.ArrowXl .slick-prev {
    background-image: url(../src/assets/images/arrowleft.svg);
    left: 50px;
}

.ArrowXl .slick-next {
    background-image: url(../src/assets/images/arrowright.svg);
    right: 50px;

}

.ArrowXl .slick-prev:hover {
    background-image: url(../src/assets/images/left_arrow_hover.svg);
}

.ArrowXl .slick-next:hover {
    background-image: url(../src/assets/images/right_arrow_hover.svg);
}

.ArrowXll .slick-prev {

    left: -130px;
    background-size: 95px;
}

.ArrowXll .slick-next {

    right: -130px;
    background-size: 95px;
}



.PressMediaSlider .pb-50 {
    padding-bottom: 110px;
}

.Caption {
    background-color: rgba(153, 137, 108, 0.68);
    padding: 10px 20px;
    text-align: center;
    font-family: 'Cormorant Infant';
    color: #fff;
    font-size: 40px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    line-height: 1.2;
    text-transform: uppercase;
}

.px-05 {
    padding-left: 2px !important;
    padding-right: 2px !important;
}


.footers .nav-link {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    color: #050504;
    padding: 3px 8px !important;
    letter-spacing: 0.6px;
}

.footers .nav-link:hover,
.footers .nav-link:active,
.footers .nav-link:focus {
    color: #97896f;
}



.SlideIm img {
    height: 100%;
}

.cls-1 {
    fill: #fff;
}



@-webkit-keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-300% * 1));
    }
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-300% * 1));
    }
}

.Homslider {
    background: white;
    margin: auto;
    overflow: hidden;
    position: relative;
}

.Homslider::before {
    left: 0;
    top: 0;
}

.Homslider .slide-track {
    -webkit-animation: scroll 30s linear infinite;
    animation: scroll 30s linear infinite;
    display: flex;
    width: calc(100% * 1);
}

.Homslider .slide {
    height: auto;
    width: 100%;
    flex: 0 0 100%;
}

.Homslider .slide img {
    width: 100%;
}


.InstaSlider .slick-slide {
    padding: 10px;
}

.AboutSlider .slick-slide {
    padding: 0 0px;
    opacity: .2;
    transition: opacity 0.8s ease-in-out;
}

.AboutSlider .slick-slide img {
    width: 100%;
}

.AboutSlider .slick-current {
    opacity: 1;
    transition: opacity 0.8s ease-in-out;
}

.AboutSlider .slick-active {
    opacity: 1;
    transition: opacity 0.8s ease-in-out;
}

.AboutSlider .slick-prev {
    left: -115px;
}

.AboutSlider .slick-next {
    right: -115px;
}

.AboutBanner {
    overflow: hidden;
}

.AboutBanner img {
    animation: 20s slidein forwards linear;
}


@keyframes slidein {

    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.3);
    }
}

.AboutAD {
    margin-top: 50px;
    margin-bottom: 50px;
}

.AboutAD h5 {
    font-weight: 600;
}

.AboutAD .slick-arrow {
    margin-top: -100px;
    border: 1px solid #99896c;
}


.AboutAD .slick-prev {
    background-image: url(../src/assets/images/arrowleftGold.svg);
    left: 0px;
}

.AboutAD .slick-next {
    background-image: url(../src/assets/images/arrowrightGold.svg);
    right: 0px;
}

.headingXl {
    text-align: left;
    font-family: 'Cormorant Infant';
    color: #151212;
    font-size: 35px;
    text-transform: uppercase;
    margin-bottom: 0;
    line-height: 0.8;
}

.headingMd {
    text-align: center;
    font-family: 'Cormorant Infant';
    color: #151212;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.AboutAD img {
    max-width: 80%;
    max-height: 180px;
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 50%, 0);
        transform: translate3d(0, 50%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 20%, 0);
        transform: translate3d(0, 20%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -60%, 0);
        transform: translate3d(0, -60%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

.PressSlider .slick-list {
    margin-left: -12px;
    margin-right: -12px;
}

.PressSlider .slick-slide {
    padding-left: 12px;
    padding-right: 12px;
}

.InteriorMedia {
    position: relative;
}

.TakeBtn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all ease 0.2s;
}

.InteriorMedia:hover .TakeBtn {
    opacity: 1;
}

.DotsSlider .slick-dots {
    display: flex !important;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-top: 0px;
    margin-bottom: 20px;
}

.DotsSlider .slick-dots li {
    padding: 0 12px;
}

.DotsSlider .slick-dots li button {
    font-size: 0;
    padding: 0;
    width: 10px !important;
    height: 10px !important;
    background-color: #dedede;
    border: 0;
}

.DotsSlider .slick-dots li.slick-active button {
    background-color: #99896c;
}

.WhyChooseFull {
    background-color: #000;
    padding: 40px 0 80px;
    border-top: 15px solid #99896c;
}


.colorWhite {
    color: #fff;
}

.VideoRelative {
    position: relative;
}

.PlayPause {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.PlayPause button {
    background-color: transparent;
    border: 0;
}

.pause {
    transform: rotate(-90deg);
}

.PlayPause button img {
    width: 130px;
}

.playPauseTopLeft {
    bottom: inherit;
    right: inherit;
    width: auto !important;
    height: auto !important;
    left: 20px !important;
    top: 30px !important;
}

.playPauseTopLeft button img {
    width: 50px;
}

.playPauseTopLeft .pause {
    transform: rotate(0deg);
}

@media (max-width: 1000px) {
    .PlayPause button img {
        width: 70px;
    }
}

@media (max-width: 400px) {
    .PlayPause button img {
        width: 40px;
    }
}

.VideoRelative video {
    width: 100%;
}

.Videoobject {
    width: 100%;
}

.Videoobject video {
    height: 25vw;
    object-fit: cover;
    width: 100%;
    cursor: pointer;
}

.WhyFulls p {
    color: #fff;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    margin-top: 8px;
}

.AbBottom {
    height: 600px;
    background-image: url(../src/assets/images/about/bannerBottom.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.Pin {
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
    position: absolute;
    width: 35px;
    right: -30px;
    top: 20px;
    height: 35px;
}

.Instaa {
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
    position: absolute;
    width: 40px;
    top: 65px;
    right: -32px;
    height: 40px;
    fill: #fff;
}

.Pin .cls-1 {
    fill: transparent;
    fill-rule: nonzero;
}

.Pin .cls-2 {
    fill: #fff;
    fill-rule: nonzero;
}

.Pin:hover .cls-2 {
    fill: #97896f;
}


.LogIms {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}




@media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1040px !important;
    }
}

@media (min-width: 992px) {
    .navbar-brand {
        position: absolute;
        bottom: calc(100% + 28px);
        left: 140px;
        width: 75px;
    }


    .consultNoHover {
        position: absolute;
        right: 0;
        height: 173px;
        z-index: 9;
        width: 52.676px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("assets/images/consult_no-hover.png");
    }

    .consultNoHover:hover {
        transition: .5s ease;
        background-image: url("assets/images/consult_hover.png");
    }


    .navbar {
        border-top: 1px solid #888887;
        border-bottom: 1px solid #888887;
        background-color: #fff !important;
        padding: 0 !important;
    }

    .navbar-expand-lg .navbar-nav {
        justify-content: center;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .PressMediaSlider .slick-arrow {
        position: absolute;
        bottom: 0;
        width: 90px;
        height: 90px;
        font-size: 0;
        border: 0.8px solid transparent;
        background-color: transparent;
        border-radius: 50%;

        background-size: 90px;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 1;
        transition: all ease 0.2s;
        left: 0;
    }


    .PressMediaSlider .slick-prev {
        left: 24%;
        background-image: url(../src/assets/images/arrowleftGold.svg);
    }

    .PressMediaSlider .slick-prev:hover {
        background-image: url(../src/assets/images/arrowleftGoldFull.svg);
    }

    .PressMediaSlider .slick-next {
        left: calc(24% + 120px);
        background-image: url(../src/assets/images/arrowrightGold.svg);
    }

    .PressMediaSlider .slick-next:hover {
        background-image: url(../src/assets/images/arrowrightGoldFull.svg);
    }
}

@media (max-width: 991px) {

    .NavLogo {
        padding: 5px 55px 5px 15px !important;
        max-width: 100%;
        margin-left: 0;
    }

    .NavLogoSide img {
        width: 100px;
    }

    .NavLogo img {
        max-height: 60px;
    }

    .footers .FotLogo {
        padding: 10px 15px !important;
        max-width: 100%;
    }

    button.navbar-toggler {
        position: absolute;
        right: 10px;
        bottom: 8vw;
        border: 0;
        padding-right: 0;
        box-shadow: none !important;
    }

    .navbar {
        padding: 0 !important;
    }

    .navbar .navbar-toggler-icon {
        background-image: none;
    }

    .navbar .navbar-toggler-icon {
        background-image: none;
        position: relative;
        padding-right: 0;
    }

    .navbar .navbar-toggler-icon:after,
    .navbar .navbar-toggler-icon:before {
        width: 25px;
        height: 1px;
        background-color: #000;
        position: absolute;
        left: 0;
        content: "";
        right: 0;
        margin: auto;
    }

    .navbar .navbar-toggler-icon:after {
        top: 12px;
    }

    .navbar .navbar-toggler-icon:before {
        top: 18px;
    }

    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
        position: fixed;
        top: 0;
        right: -240px;
        bottom: 0;
        height: 100vh !important;
        background-color: #fff;
        z-index: 111;
        padding: 20px;
        width: 240px;
        padding-top: 10px;
        transition: all linear 0.2s !important;
    }

    .navbar-collapse.collapse {
        right: 0px;
    }

    .navbar-collapse button.navbar-toggler {
        top: 5px;
        margin-left: auto;
        display: flex;
        right: 10px;
    }

    .navbar .navbar-collapse .navbar-toggler-icon:before {
        top: 15px;
        transform: rotate(42deg);
    }

    .navbar .navbar-collapse .navbar-toggler-icon:after {
        top: 15px;
        transform: rotate(-42deg);
    }

    .navbar-brand {
        display: block;
        max-width: 80px;
    }

    .navbar .nav-link {
        font-size: 14px;
        padding: 3px 0px !important;
        letter-spacing: 1.5px;
    }

    .navbar-nav {
        padding-top: 25px;
    }

    .MobileCircleImg {
        margin-top: 150px;
        max-width: 150px;
    }

    .PressMediaSlider img {
        margin: auto;
    }


    .PressMediaSlider .slick-arrow,
    .PressSlider .slick-arrow {
        position: absolute;
        width: 28px;
        height: 28px;
        font-size: 0;
        border: 0;
        background-color: #000;
        background-size: 60px;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 1;
        transition: all ease 0.2s;
        top: 20%;
    }

    .PressMediaSlider .slick-prev,
    .PressSlider .slick-prev {
        left: -11px;
        background-position: -12px 50%;
        background-image: url(../src/assets/images/arrowleft.svg);
    }

    .PressMediaSlider .slick-next,
    .PressSlider .slick-next {
        right: -11px;
        background-position: -20px 50%;
        background-image: url(../src/assets/images/arrowright.svg);
    }

    .PressSlider .slick-prev,
    .PressSlider .slick-next {
        top: inherit;
        bottom: 100px;
    }

    .PressSlider .slick-prev {
        left: 0px;
    }

    .PressSlider .slick-next {
        right: 0px;
    }


    .PressSlider {
        position: relative;
    }

    .PressSlider .slick-slider {
        position: static;
    }

    .PressMediaSlider .pb-50 {
        padding-bottom: 20px;
    }

}




@media (min-width: 768px) {}

@media (max-width: 767px) {


    .CtaBtn {
        max-width: 140px;
        min-width: 140px;
    }

    .TakeBtn a.CtaBtn {
        min-width: 85px;
        max-width: 85px;
    }

    .Caption {
        background-color: transparent;
        color: #000;
        font-size: 30px;
        position: relative;
        line-height: 1;
    }

    .feat-img img {
        /*height: 370px;*/
        height: auto;
        object-fit: cover;
    }

    .feat-img .CtaBtn img {
        height: auto;
        object-fit: inherit;
    }


    .footers .nav-link {
        padding: 1px 15px !important;
        letter-spacing: 1.6px;
        display: block;
        width: 100%;
        text-align: center;
    }

    .footers .nav {
        margin: 20px 0;
    }

    .footers .FotLogo {
        padding: 0 !important;
    }

    .headingMd {
        font-size: 30px;
    }

    .headingXl {
        font-size: 32px;
        text-align: center;
    }


    .AbBottom {
        height: 400px;
    }

    .AboutAD {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .Homslider .slide-track {
        width: calc(100% * 2);
    }

    .Videoobject video {
        height: 100%;
    }

    .WhyChooseFull {
        padding: 0;
        background-color: transparent;
    }

    .WhyFulls p {
        color: #000;
    }

    .WhyChooseFull ul.slick-dots {
        list-style: none;
        position: absolute;
        bottom: 80px;
        right: 30px;
    }

    .WhyChooseFull ul.slick-dots li {
        margin: 10px 0;
    }

    .WhyChooseFull ul.slick-dots button {
        font-size: 0;
        width: 8px;
        height: 8px;
        border: 0;
        background-color: #fff;
        padding: 0;
    }


}

@media (max-width: 990px) {
    .consultNoHover {
        display: none;
    }
}

.text-center.logos p {
    line-height: 18px;
}

.detailContent {
    padding-top: 22px;
}

.whyChoose {
    font-size: 30px;
    text-transform: uppercase;
    color: #000;
    font-weight: 400;
    font-family: Cormorant Infant;
}

.whyclient.row h2.whyChoose {
    display: none;
}




.boxArrow {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;

}

.clsOne {
    fill: #fff
}

.clsTwo {
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 1.42px;
}

.boxLink {
    font-family: 'Cormorant Infant';
    color: #FFF;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-decoration: none;

}

.boxLink:hover {
    color: #97896f;
}

.boxLink svg {
    width: 60px;

}

.boxLink:hover .clsOne {
    fill: #97896f;
}

.TabsVideos {
    display: flex;
    flex-direction: column-reverse;
}

.TabsVideos .nav-tabs {
    border-bottom: 0;
}

.TabsVideos .nav-tabs {
    display: flex;
    justify-content: center;
}

.TabsVideos .nav-tabs .nav-item {
    margin: 5px 12px;
}

.TabsVideos .nav-tabs .nav-link {
    font-size: 0;
    border: 0 !important;
    background-color: #dedede !important;
    width: 10px !important;
    padding: 0 !important;
    height: 10px !important;
    border-radius: 0 !important;
    cursor: pointer;
}

.TabsVideos .nav-tabs .nav-link.active {
    background-color: #97896f !important;
}

.ComBlack {
    background-color: #000;
    padding: 35px 50px;
}

.form-control {
    border-radius: 0 !important;
    border: 0 !important;
    border-bottom: 1px solid #000 !important;
    letter-spacing: 1px !important;
    padding: 16px 20px !important;
    color: #000 !important;
    font-size: 15px !important;
    text-transform: uppercase !important;
    font-weight: 500;
}

select.form-control {
    background-image: url(../src/assets/images/arrowBlack.png);
    background-repeat: no-repeat;
    background-position: right 20px bottom 13px;
    background-size: 20px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.form-control::-webkit-input-placeholder {
    color: #000 !important;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #000 !important;
    opacity: 1;
}

.form-control::placeholder {
    color: #000 !important;
    opacity: 1;
}

.Height10 {
    height: 12px;
    background-color: #99896c;
}

.InstaSlider {
    position: relative;
    padding-left: 50px;
    padding-right: 50px;
}

.clienteleBg {
    background-image: url(../src/assets/images/clientele.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;

    background-size: cover;
    background-position: right 10vw top 0;
    color: #fff;
    padding: 80px 40px;
}

@media (min-width:768px) {
    .TabsVideos .nav-tabs {
        justify-content: end;
        margin-top: -40px;
    }

}

h2.headingXl.interior {
    text-align: center;
    font-size: 30px;
}

/* .makeStyles-ConMobile-11 p {
    font-size: 12px;
} */

svg.Instaa:hover {
    fill: #99896c;
}

@media (max-width:768px) {
    .LogIms {
        min-height: inherit;
    }

    h2.headingXl.interior {
        font-size: 24px;
    }

    a.boxLink {
        color: black;
    }

    .boxLink svg {
        width: 60px;
        filter: invert(100%);

    }

    .clienteleBg {
        background-attachment: inherit;
        background-position: right center;
        padding: 80px 20px;
    }

    .Pin {
        height: 26px;
        right: 14px;
    }

    .Instaa {
        height: 30px;
        right: 10px;
    }

    .col-lg.paddingTop {
        padding-top: 10px;
    }

    .col-lg.paddingTopSecond {
        padding-top: 30px;
    }
}